import AWS from "aws-sdk";

const S3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_S3_REGION,
});

export async function S3Data(prefix) {
  try {
    const params = {
      Bucket: "daily-sarah",
      Prefix: prefix,
    };

    return await S3.listObjectsV2(params).promise();
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function S3DataMain(key) {
  try {
    const params = {
      Bucket: "daily-sarah",
      Key: key,
    };

    return await S3.getObject(params).promise();
  } catch (error) {
    return null;
  }
}
