import { React, useState } from "react";
import AppLogo from "../../assets/Q Logo.jpg";
import {
  Drawer,
  List,
  Toolbar,
  Box,
  AppBar,
  Typography,
  Button,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";

//component styles
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.textSecondary.main + " !important",
  zIndex: "1000",
  position: "relative",
}));
const StyledDrawer = styled(Drawer)(({ theme }) => ({
  "& div.MuiPaper-root": {
    backgroundColor: theme.palette.background.main,
    width: "50vw",
    maxWidth: "20rem",
    textAlign: "center",
    justifyContent: "center",
    padding: "1.5rem 0 0 0",
    color: "black !important",
    zIndex: "2 !important",
  },
}));
const StyledNavbarLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: "white",
  fontSize: "1.25rem",
  fontFamily: "'Fira Sans Extra Condensed', sans-serif;",
}));
const StyledDrawerLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.textSecondary.main,
  fontSize: "1rem",
  fontFamily: "'Fira Sans Extra Condensed', sans-serif;",
}));

export default function ButtonAppBar() {
  const theme = useTheme();
  const collapse = useMediaQuery(theme.breakpoints.down("md"));
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = (isOpen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen(isOpen);
  };

  const drawer = (
    <>
      <Button onClick={toggleDrawer(true)}>
        <MenuIcon sx={{ color: "#FFFFFF" }} />
      </Button>

      <StyledDrawer
        anchor={"right"}
        variant="temporary"
        open={isOpen}
        onClose={toggleDrawer(false)}
      >
        <Box role="presentation" onKeyDown={toggleDrawer(false)}>
          <List>
            <Button onClick={toggleDrawer(false)}>
              <StyledDrawerLink to="/">Main</StyledDrawerLink>
            </Button>
          </List>
          <List>
            <Button onClick={toggleDrawer(false)}>
              <StyledDrawerLink to="/about">About</StyledDrawerLink>
            </Button>
          </List>
          <List>
            <Button onClick={toggleDrawer(false)}>
              <StyledDrawerLink to="/archives">Archives</StyledDrawerLink>
            </Button>
          </List>
          <List>
            <Button onClick={toggleDrawer(false)}>
              <StyledDrawerLink to="/gallery">Gallery</StyledDrawerLink>
            </Button>
          </List>
        </Box>
      </StyledDrawer>
    </>
  );

  const navbar = (
    <>
      <Button>
        <StyledNavbarLink to="/">Main</StyledNavbarLink>
      </Button>
      <Button>
        <StyledNavbarLink to="/about">About</StyledNavbarLink>
      </Button>
      <Button>
        <StyledNavbarLink to="/archives">Archives</StyledNavbarLink>
      </Button>
      <Button>
        <StyledNavbarLink to="/gallery">Gallery</StyledNavbarLink>
      </Button>
    </>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <StyledAppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            <Button
              onClick={() => {
                window.location.reload();
              }}
            >
              <img
                alt=""
                src={AppLogo}
                style={{ width: "2.5rem", borderRadius: "2rem" }}
              />
            </Button>
          </Typography>
          {collapse ? drawer : navbar}
        </Toolbar>
      </StyledAppBar>
    </Box>
  );
}
