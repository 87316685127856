import { React, useEffect, useState } from "react";
import {
  Card,
  CardMedia,
  CardContent,
  styled,
  useTheme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { S3DataMain } from "../../S3Data";
import seedrandom from 'seedrandom';

const StyledMainContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  display: "flex",
  alignItems: "center",
  position: "absolute",
  top: "0",
  bottom: "0",
  left: "0",
  right: "0",
}));
const StyledMainCard = styled(Card)(({ theme }) => ({
  margin: "auto",
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  borderRadius: "2rem",
  backgroundColor: theme.palette.backgroundSecondary.main,
}));

function Main() {
  const baseUrl = "https://daily-sarah.s3.amazonaws.com/";
  const imgExt = "photos/";
  const theme = useTheme();
  const [text, setText] = useState("");
  const [img, setImg] = useState("");
  const [aspectRatio, setAspectRatio] = useState(1);
  //let date_1 = new Date("4/18/2023");
  let date_2 = new Date();
 // let difference = date_2.getTime() - date_1.getTime();
 const  currentDate  = date_2;
 const dateSeed = currentDate.toISOString().slice(0, 10); // Use the date as a seed
 // Create a consistent random number generator with the date seed
 const generator = seedrandom(dateSeed);
 // Generate a random integer between 1 and 121 (inclusive)
 const randomValue = Math.floor(generator() * 121) + 1;
 let day = randomValue;
  //let day = Math.ceil(difference / (1000 * 3600 * 24));

  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const xhr = new XMLHttpRequest();
  xhr.open("GET", `https://daily-sarah.s3.amazonaws.com/photos/${day}`);
  xhr.setRequestHeader("Cache-Control", "no-cache");
  xhr.send();

  //Fetch text data from s3 bucket on load
  useEffect(() => {
    async function fetchData() {
      //const data = await S3DataMain(`text/${day}.txt`);
      const [imgData, textData] = await Promise.all([
        S3DataMain(`photos/${day}`),
        S3DataMain(`text/${day}.txt`),
      ]);
      setText(textData ? textData.Body.toString() : "");
      setImg(imgData ? `${baseUrl}${imgExt}${day}` : "");
    }
    fetchData();

    //setImg(`${baseUrl}${imgExt}4`);
  }, [day, img]);

  //get Image aspect ratio
  const getMeta = (url, cb) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
  };

  useEffect(() => {
    getMeta(`${baseUrl}${imgExt}${day}`, (err, img) => {
      setAspectRatio(img.naturalWidth / img.naturalHeight);
    });
  }, [day]);

  return (
    <StyledMainContainer>
      <StyledMainCard>
        <CardMedia image="/path/to/image.jpg" title="Image title" />
        <CardContent sx={{ textAlign: "center" }}>
          <Typography
            sx={{
              fontFamily: "'Fira Sans Extra Condensed', sans-serif;",
              fontSize: "2rem",
            }}
          >
            Day {day}
          </Typography>
          {text && <Typography sx={{ fontSize: "1.5rem" }}>{text}</Typography>}
          {img && (
            <>
              <img
                alt=""
                style={
                  aspectRatio > 0
                    ? {
                        width: sm
                          ? (200 * aspectRatio).toString() + "px"
                          : (300 * aspectRatio).toString() + "px",
                        height: sm ? "200px" : "300px",
                        margin: sm ? "1rem 0rem" : "1rem",
                      }
                    : {
                        width: sm ? "200px" : "300px",
                        height: sm
                          ? (200 * aspectRatio).toString() + "px"
                          : (300 * aspectRatio).toString() + "px",
                        margin: sm ? "1rem 0rem" : "1rem",
                      }
                }
                src={img}
              />
            </>
          )}
        </CardContent>
      </StyledMainCard>
    </StyledMainContainer>
  );
}

export default Main;
