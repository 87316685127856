import { React, useState, useEffect } from "react";
import {
  Grid,
  Pagination,
  useMediaQuery,
  useTheme,
  styled,
} from "@mui/material";
import { S3Data } from "../../S3Data";
import Footer from "../footer/Footer";

//component styles
const StyledRootContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  minHeight: "85%",
  overflow: "hidden",
});
const StyledGridContainer = styled(Grid)(({ theme }) => ({
  margin: "4rem",
  justifyContent: "center",
  gap: "4rem",
  [theme.breakpoints.down("sm")]: {
    margin: "2rem",
  },
}));
const StyledGridPagination = styled(Pagination)(({ theme }) => ({
  "& ul": {
    justifyContent: "center",
    "& li": {
      "& button": {
        fontSize: "2rem",
        [theme.breakpoints.down("sm")]: {
          fontSize: "1rem",
        },
      },
    },
  },
}));

const baseUrl = "https://daily-sarah.s3.amazonaws.com/";

function Gallery() {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const [items, setItems] = useState([]);

  // State to manage pagination
  const [page, setPage] = useState(1);
  const itemsPerPage = 4;
  const pageCount = Math.ceil(items.length / itemsPerPage);

  // Handle page change
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  //Fetch gallery data from s3 bucket on load
  useEffect(() => {
    async function fetchData() {
      const data = await S3Data("gallery2");
      console.log(data);
      setItems(data.Contents.slice(1));
    }
    fetchData();
  }, []);

  const getMeta = (url, cb) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
  };

  //Get the image sizes after s3 bucket data loads
  useEffect(() => {
    for (let i = 1; i < items.length; i++) {
      getMeta(`${baseUrl}${items[i].Key}`, (err, img) => {
        let aspectRatio = img.naturalWidth / img.naturalHeight;
        items[i].aspectRatio = aspectRatio;
      });
    }
  }, [items]);

  return (
    <div style={{}}>
      <StyledRootContainer>
        <StyledGridContainer container spacing={4}>
          {items
            .slice((page - 1) * itemsPerPage, page * itemsPerPage)
            .map((item) => {
              return (
                <Grid
                  item
                  key={item.ETag}
                  xs={sm ? 12 : null}
                  sx={{
                    alignSelf: "center",
                    marginTop: "32px",
                    paddingLeft: "0 !important",
                  }}
                >
                  <img
                    alt=""
                    style={
                      item.aspectRatio > 0
                        ? {
                            width: (230 * item.aspectRatio).toString() + "px",
                            height: "230px",
                          }
                        : {
                            width: "230px",
                            height: (230 * item.aspectRatio).toString() + "px",
                          }
                    }
                    src={`${baseUrl}${item.Key}`}
                  />
                </Grid>
              );
            })}
          <Grid
            item
            xs={12}
            sx={{
              position: "relative !important",
              paddingLeft: "0 !important",
            }}
          >
            <StyledGridPagination
              count={pageCount}
              page={page}
              onChange={handlePageChange}
            />
          </Grid>
        </StyledGridContainer>
      </StyledRootContainer>
      <Footer />
    </div>
  );
}

export default Gallery;
