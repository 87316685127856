import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/navigation/Navbar";
import Main from "./components/main/Main";
import Gallery from "./components/gallery/Gallery";
import Archives from "./components/archives/Archives";
import { ThemeProvider } from "@mui/material";
import { theme } from "./Theme";
import About from "./components/about/About";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Navbar />
        <div
          style={{
            backgroundColor: theme.palette.background.main,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Routes>
            <Route exact path="/" element={<Main />}></Route>
            <Route exact path="/about" element={<About />}></Route>
            <Route exact path="/archives" element={<Archives />}></Route>
            <Route exact path="/gallery" element={<Gallery />}></Route>
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
