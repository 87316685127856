import { React } from "react";
import { styled, Typography } from "@mui/material";
import SarahImg from "../../assets/sarah.jpg";
import Footer from "../footer/Footer";

const StyledMainContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  margin: "6rem 12rem",
  gap: "4rem",
  alignSelf: "center",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    margin: "2rem 2rem",
    width: "90%",
    gap: "2rem",
  },
}));

const StyledTextContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "2rem",
});

const StyledText = styled(Typography)({
  textIndent: "2rem",
  fontSize: "1.2rem",
});

const StyledImg = styled("img")(({ theme }) => ({
  width: "30rem",
  height: "30rem",
  borderRadius: "0.5rem",
  [theme.breakpoints.down("lg")]: {
    width: "16.5rem",
    height: "22rem",
  },
}));

function About() {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100%" }}
    >
      <StyledMainContainer>
        <StyledImg src={SarahImg} />
        <StyledTextContainer>
          <StyledText>
            {"    "}
            <span style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
              Sarah Quickel,
            </span>{" "}
            my sister, was the most thoughtful and introspective person I have ever met. Anyone who is visiting this site most likely knows about the tragic nature of her death in December 2022.  One of the main comforts that has helped me through this time is the amount of advice, expression, and her very spirit which Sarah left to us through her writings. It is an amazing gift to have had such a talented sister who left behind her artwork, journals, and even the beginnings of a novel. I decided, along with my family, that her work and wisdom should not be contained to just us, but should be shared with everyone who had the pleasure of knowing her. I wanted to create a site that wasn't something static but something emblematic of Sarah's spirit and something that helps everyone to have a piece of her every day, or whenever they may need “a dose of Sarah”.
          </StyledText>
          <StyledText>
            {"    "}In the main section of the site there will be a daily post
            displaying an excerpt from her many mediums of expression. 
            </StyledText>
            <StyledText>
            {"    "}While I do have lots of content for this site, I do want to keep posting as long as possible.  If you have anything you would like to contribute, whether it be a personal experience with her, artwork, or anything else that reminds you of Sarah, please reach out to either my family, or me directly, and I will share it.
          </StyledText>
          <StyledText>
            {"    "}I also want to send a special thank you to my friend, Max, who provided me key assistance throughout  the development of this site.
            </StyledText>
            <StyledText>
            {"    "}Love you forever Sis &lt;3 Stephen
          </StyledText>
        </StyledTextContainer>
      </StyledMainContainer>
      <Footer />
    </div>
  );
}

export default About;
