import { React } from "react";
import { Typography, styled } from "@mui/material";

//component styles
const StyledFooterContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.backgroundSecondary.main + " !important",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "12rem",
  padding: "0.5rem 1rem",
}));

const StyledFooterText = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  textAlign: "center",
  [theme.breakpoints.down("md")]: {
    fontSize: "1.2rem",
    maxWidth: "70%",
  },
}));

function Footer() {
  return (
    <StyledFooterContainer>
      <StyledFooterText>
        To purchase Sarah's art in the form of paintings, drawings, T-shirts,
        phone cases, and much more you can proceed to the following link:{" "}
        <a
          style={{ color: "unset", whiteSpace: "nowrap" }}
          href="https://sarah-quickel.pixels.com"
          target="_blank"
          rel="noreferrer"
        >
          sarah-quickel.pixels.com
        </a>{" "}
        All proceeds will go to a Sarah fund to causes we believe were important
        to her.
      </StyledFooterText>
    </StyledFooterContainer>
  );
}

export default Footer;
