import { createTheme } from "@mui/material";

export const theme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  typography: {
    fontFamily: "'Caveat', cursive",
  },
  palette: {
    background: {
      main: "#F7F3DC",
    },
    backgroundSecondary: {
      main: "#F7E6D0",
    },
    buttonHover: {
      main: "#F7D3AD",
    },
    textMain: {
      main: "#EDB791",
    },
    textSecondary: {
      main: "#D19E82",
    },
  },
});
